<template>
  <div class="statistics">
    <div class="d-flex justify-content-between mb-2">
      <p class="main-header-section">
        Статистика
        <span
          v-if="
            !Statistics.is_loading &&
            Statistics.filters.select !== 'today' &&
            Statistics.filters.select !== 'yesterday'
          "
        >
          с {{ Statistics.o_start }} по {{ Statistics.o_end }}
        </span>
      </p>
      <div class="download-outline-btn" @click="exportExcel">
        <feather-icon icon="DownloadIcon" style="margin-right: 8px" size="18" />
        Сохранить отчет
      </div>
    </div>

    <filters-view />

    <table-view :Statistics="Statistics" />
  </div>
</template>

<script>
import Statistics from "@/modules/contents/statistics";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import tableView from "../tables/desktop";
import FiltersView from "../widgets/filters";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      Statistics,
    };
  },
  props: {},
  methods: {
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },
    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
    loadMore() {},
    changeActiveCategories(categories) {},
    exportExcel() {
      if (Statistics.items.length > 0)  {
        this.$request.export("export/statistics", Statistics.filters).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let fileName = 'статистика';
          if (Statistics.o_start && Statistics.o_start.length > 0) fileName += '_c_'+Statistics.o_start;
          if (Statistics.o_end && Statistics.o_end.length > 0) fileName += '_по_'+Statistics.o_end;
          fileName += '.xlsx';
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            text: "Нет данных для для отчета.",
            icon: "CheckCircleIcon",
            variant: "warning",
          },
        });
      }
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    FiltersView,
    tableView,
  },

  computed: {},

  watch: {},
  mounted() {
    this.$router.replace({ query: {} });
    Statistics.list();
  },
};
</script>

<style lang="scss"></style>
